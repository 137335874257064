@media screen and (min-width: 1200px) {
    .bottom-nav{
        display: none !important;
        visibility: hidden;
        margin-top: 50px;
    }
    }

    
    .bottom-nav{
        background: #E94036;
        color: whitesmoke;
    }
    
    .bottom-nav-item.active {
        color: white;
        font-weight: 600;
    }
    
    .nav-icon.active {
        color: white;
    }
    .nav-icon {
        color: white;
    }
    
    
    @media screen and (min-width: 1200px) {
        .bottom-nav{
            display: none !important;
            visibility: hidden;
            margin-top: 50px;
        }
    }
    
    @media screen and (max-width: 768px) {
        .bottom-nav{
            width: 100%;
        }
    }